.form-background {
  background-image: url("../../assets/images/backgroundimage.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.95;
  min-height: 92.8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-form {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 2.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.4s ease-in-out;
  max-width: 600px;
  margin: 3rem auto;

  .header-title {
    text-align: center;
    color: #1a1a1a;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .form-group {
    margin-bottom: 1.5rem;

    input,
    textarea {
      width: 100%;
      padding: 1rem;
      border: 1px solid #e0e0e0;
      border-radius: 0.5rem;
      font-size: 1rem;
      background-color: #f8f8f8;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.15);
      }
    }
  }

  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    button {
      padding: 0.9rem 1.5rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      width: 48%;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      &:hover {
        transform: translateY(-2px);
      }
    }

    .submit-btn {
      background-color: #007bff;
      color: white;
    }

    .clear-btn {
      background-color: transparent;
      border: 1px solid #dc3545;
      color: #dc3545;

      &:hover {
        background-color: #dc3545;
        color: white;
      }
    }
  }
}

// Animation for the form
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media query for 375×667 (iPhone 8, iPhone SE 2020) */
@media only screen and (max-width: 375px) and (max-height: 667px) {
  .client-form {
    font-size: 14px;
    padding: 10px;
  }
}

/* Media query for 414×736 (iPhone 6/7/8 Plus) */
@media only screen and (max-width: 414px) and (max-height: 736px) {
  .client-form {
    font-size: 14px;
    padding: 12px;
  }
}

/* Media query for 360×800 (Android phones, e.g., Samsung Galaxy S9) */
@media only screen and (max-width: 360px) and (max-height: 800px) {
  .client-form {
    font-size: 13px;
    padding: 8px;
  }
}

/* Media query for 390×844 (iPhone 12, iPhone 13) */
@media only screen and (max-width: 390px) and (max-height: 844px) {
  .client-form {
    font-size: 14px;
    padding: 10px;
  }
}

/* Media query for 601×962 (small tablets) */
@media only screen and (max-width: 601px) and (max-height: 962px) {
  .client-form {
    font-size: 15px;
    padding: 16px;
  }
}

/* Media query for 768×1024 (iPad Mini, iPad 9.7") */
@media only screen and (max-width: 768px) and (max-height: 1024px) {
  .client-form {
    font-size: 16px;
    padding: 20px;
  }
}

/* Media query for 800×600 (small monitors) */
@media only screen and (max-width: 800px) and (max-height: 600px) {
  .client-form {
    font-size: 15px;
    padding: 14px;
  }
}

/* Media query for 1024×768 (iPad Air) */
@media only screen and (max-width: 1024px) and (max-height: 768px) {
  .client-form {
    font-size: 16px;
    padding: 18px;
  }
}

/* Media query for 1366×768 (laptops) */
@media only screen and (max-width: 1366px) and (max-height: 768px) {
  .client-form {
    font-size: 16px;
    padding: 20px;
  }
}

/* Media query for 1600×900 (larger laptops) */
@media only screen and (max-width: 1600px) and (max-height: 900px) {
  .client-form {
    font-size: 18px;
    padding: 24px;
  }
}

/* Media query for 1920×1200 (larger monitors) */
@media only screen and (max-width: 1920px) and (max-height: 1200px) {
  .client-form {
    font-size: 18px;
    padding: 26px;
  }
}

/* Media query for 2560×1600 (high-resolution monitors) */
@media only screen and (max-width: 2560px) and (max-height: 1600px) {
  .client-form {
    font-size: 20px;
    padding: 30px;
  }
}

/* Media query for 3440×1440 (ultrawide monitors) */
@media only screen and (max-width: 3440px) and (max-height: 1440px) {
  .client-form {
    font-size: 22px;
    padding: 32px;
  }
}

/* Media query for 5120×2880 (5K monitors) */
@media only screen and (max-width: 5120px) and (max-height: 2880px) {
  .client-form {
    font-size: 24px;
    padding: 36px;
  }
}

/* Media query for 320×240 (very small screens) */
@media only screen and (max-width: 320px) and (max-height: 240px) {
  .client-form {
    font-size: 12px;
    padding: 6px;
  }
}

/* Media query for 1280×800 (older laptops) */
@media only screen and (max-width: 1280px) and (max-height: 800px) {
  .client-form {
    font-size: 16px;
    padding: 20px;
  }
}

/* Media query for 2880×1800 (MacBook Pro Retina) */
@media only screen and (max-width: 2880px) and (max-height: 1800px) {
  .client-form {
    font-size: 22px;
    padding: 30px;
  }
}

/* Media query for 5120×2160 (high-res wide monitors) */
@media only screen and (max-width: 5120px) and (max-height: 2160px) {
  .client-form {
    font-size: 24px;
    padding: 36px;
  }
}

/* Media query for 3840×1600 (ultrawide 4K monitors) */
@media only screen and (max-width: 3840px) and (max-height: 1600px) {
  .client-form {
    font-size: 22px;
    padding: 32px;
  }
}

/* Media query for 6400×4800 (extremely high-resolution displays) */
@media only screen and (max-width: 6400px) and (max-height: 4800px) {
  .client-form {
    font-size: 28px;
    padding: 40px;
  }
}
