/* styles.scss */

.confirmation-box {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    animation: slideUp 0.5s ease-in-out; /* Animation applied */
    
    /* Animation keyframes */
    @keyframes slideUp {
      0% {
        opacity: 0;
        transform: translateY(30px); /* Starts from below */
      }
      100% {
        opacity: 1;
        transform: translateY(0); /* Moves into place */
      }
    }
  
    h5 {
      font-size: 1.5rem;
      margin-bottom: 10px;
      color: #343a40;
    }
  
    .confirmation-message {
      font-size: 1rem;
      color: #6c757d;
      margin-bottom: 20px;
    }
  
    .btn-group {
      display: flex;
      justify-content: center;
  
      .btn {
        margin: 0 10px;
      }
  
      .btn-confirm {
        background-color: #28a745;
        color: #fff;
  
        &:hover {
          background-color: #218838;
        }
      }
  
      .btn-cancel {
        background-color: #dc3545;
        color: #fff;
  
        &:hover {
          background-color: #c82333;
        }
      }
    }
  }
  