/* Navbar styling */ 
.custom-navbar {
  background-color: #ffffff; /* White background for a modern look */
  padding: 15px 30px; /* Adjust padding for a sleek design */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Softer shadow for a modern effect */
  border-bottom: 1px solid #e0e0e0; /* Light bottom border for separation */

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-brand {
    font-size: 28px; /* Increased font size */
    color: #2c3e50; /* Darker color for contrast */
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    .logo {
      width: 60px; /* Adjust logo size */
      height: auto; /* Maintain aspect ratio */
      margin-right: 10px;
    }

    &:hover {
      color: #007bff; /* Change color on hover */
      text-decoration: none;
    }
  }

  .nav-buttons {
    display: flex;

    .btn {
      padding: 0.6rem 1.2rem; /* Reduced padding for smaller buttons */
      border-radius: 0.4rem; /* Slightly smaller border radius */
      font-size: 0.7rem; /* Reduced font size */
      font-weight: 600; /* Slightly bolder font weight */
      cursor: pointer;
      transition: all 0.3s ease; /* Smooth transition */
      text-transform: uppercase;
      letter-spacing: 1px; /* Increased letter spacing for premium feel */
      margin-left: 10px; /* Reduced space between buttons */
      width: auto; /* Automatic width to fit content */
      
      /* Classy hover effect */
      &:hover {
        transform: translateY(-2px); /* Lift effect */
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow on hover */
      }
    }

    .btn-quote {
      background: linear-gradient(90deg, #007bff, #0056b3); /* Premium gradient */
      color: white;
      border: none; /* No border */

      &:hover {
        background: linear-gradient(90deg, #0056b3, #004085); /* Darker gradient on hover */
      }
    }

    .btn-login {
      background-color: #dc3545; /* Premium red color */
      color: white; /* White text */
      border: none; /* No border */
      border-radius: 0.4rem; /* Slightly smaller border radius */
  
      &:hover {
        background-color: #c82333; /* Darker red on hover */
        color: white; /* Ensure text remains white */
      }
    }
  }
}
