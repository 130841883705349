.admin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh; // Full viewport height to vertically center
  overflow: hidden; // Hide any overflow

  .form-background {
    background-image: url("../../assets/images/backgroundimage.jpg");
    background-size: cover; // Ensure the image covers the entire area
    background-position: center; // Center the image
    background-repeat: no-repeat; // Prevent repeating the image
    width: 100vw; // Full viewport width
    height: 92.8vh; // Full viewport height
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .admin-form {
    background-color: rgba(255, 255, 255, 0.85); // Semi-transparent white for a modern look
    padding: 30px; // Padding for spacing inside the form
    border-radius: 12px; // Rounded corners for a softer appearance
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    max-width: 400px; // Max width for the form
    width: 100%; // Ensure it takes full width inside the container
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.5s ease-in-out; // Optional animation

    .form-title {
      text-align: center;
      color: #2c3e50; // Dark color for contrast
      font-size: 24px; // Larger font size for emphasis
      margin-bottom: 20px; // Space below the title
      text-transform: uppercase; // Uppercase for professionalism
    }

    input {
      margin-bottom: 15px; // Space between inputs
      padding: 12px; // Increased padding for comfort
      border: 1px solid #e0e0e0; // Light border color
      border-radius: 8px; // Rounded corners for inputs
      font-size: 16px;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #007bff; // Blue border on focus
        outline: none; // Remove default outline
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); // Blue glow effect
      }
    }

    .login-button {
      padding: 12px; // Consistent padding
      background-color: #007bff; // Primary blue color
      color: white;
      font-size: 16px;
      font-weight: bold;
      border: none; // No border
      border-radius: 8px; // Rounded corners
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease; // Smooth transitions

      &:hover {
        background-color: #0056b3; // Darker blue on hover
        transform: translateY(-2px); // Lift effect
      }
    }
  }
}

// Animation for the form
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px); // Slight upward motion on appearance
  }
  to {
    opacity: 1;
    transform: translateY(0); // Return to original position
  }
}
