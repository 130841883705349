.admin-dashboard {
  padding: 20px;

  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: #2c3e50;
  }

  .table-container {
    margin: 0 auto;
    max-width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #f5f7fa;
    border-radius: 12px;
    padding: 20px;
    border: 1px solid #e0e0e0;
  }

  // Modern button styles
  .btn-export,
  .btn-delete {
    margin-top: 20px;
    display: block;
    width: 160px;
    margin-left: auto;
    margin-right: auto;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 30px; // Fully rounded corners
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }

  .btn-export {
    background: linear-gradient(
      135deg,
      #28a745,
      #2ecc71
    ); // Modern gradient for the button
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(40, 167, 69, 0.4); // Soft shadow for depth

    &:hover {
      background: linear-gradient(
        135deg,
        #218838,
        #27ae60
      ); // Darker gradient on hover
      transform: translateY(-2px); // Lift on hover
      box-shadow: 0 6px 16px rgba(40, 167, 69, 0.5); // Deeper shadow on hover
    }
  }

  .btn-delete {
    background: linear-gradient(
      135deg,
      #dc3545,
      #e74c3c
    ); // Modern red gradient for delete
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(220, 53, 69, 0.4); // Soft shadow for depth

    &:hover {
      background: linear-gradient(
        135deg,
        #c82333,
        #e74c3c
      ); // Darker red gradient on hover
      transform: translateY(-2px); // Lift on hover
      box-shadow: 0 6px 16px rgba(220, 53, 69, 0.5); // Deeper shadow on hover
    }
  }

  // Responsive styling
  @media (max-width: 768px) {
    .btn-export,
    .btn-delete {
      font-size: 0.9rem;
      padding: 10px 20px;
      width: 140px;
    }
  }
}
